.Virtual{
    margin-top: 6rem;
    margin-bottom: 6rem;
    padding: 2rem;
    display: flex;
    justify-content: space-around;

}


.left{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;

}

.left>:nth-child(1){
    font-size: 2rem;
    text-transform: uppercase;
    display: block;
    font-weight: bold;
}

.left>:nth-child(2){
    font-size: 1.6rem;
    text-transform: uppercase;
    display: block;
    width: 20rem;

}

.left>:nth-child(3){
    font-size: 1.6rem;  
    font-weight: bold;

}

.left>img{
    width: 10rem;
    margin-top: -1.6rem;
    margin-left: -1.6rem;
}

.wrapper{
    width:30rem;
}

@media screen and (max-width:640px){
    .Virtual{
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 2rem;
        display: grid;
        grid-template-rows: auto auto;
    
    }

    .left{
        
        gap: 1rem;
    
    }

    .wrapper{
        margin-left: 2rem;
        width: 100%;
        height: auto;
    }
    .left>:nth-child(1){
        margin-left: 5rem;
        font-size: 1.6rem;
    }
    .left>:nth-child(2){
        font-size: 1rem;
        margin-left: 5rem;
        width: 60%;
    }
    .left>:nth-child(3){
        font-size: 1.5rem;  
        font-weight: bold;
        margin-left: 5rem;
    
    }
    .left>img{
        width: 10rem;
        margin-left: 4rem;
    }


    
}
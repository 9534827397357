.container{
    padding: 0.2rem;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    justify-content: center;

}

.container>img{
    position: absolute;
    width: 8rem;
    left:30%;
    top:-3rem;
}


.name{
    display: flex;
    flex-direction: column;
}


.nameN{
    font-size: 1.4rem;
    font-weight: 600;
  }
  
.nameD{
    font-size: 0.7rem;
  }
.nameP{
    font-size: 1.6rem;
    font-weight: 800;
    margin-top: 0.8rem;
  }  

.nameS{
    font-size: 0.6rem;
    border: 1px solid var(--black);
    padding: 5px 10px;
    width: max-content;
    border-radius: 15px;
    margin-left: -0.3rem;

}
.products{
    display: grid;
    width: 90%;
    grid-template-columns: 25% auto;
}

.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap:2rem;
    font-weight:500;
    font-size: 1.3rem;

}

.menu>li:hover{
    color: var(--pink);
    cursor: pointer;
}

.list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px , 1fr));
    height: 25rem;
    overflow-y: scroll;
    grid-gap:2rem;
    justify-content: space-between;
}

.product{
    width: 12rem;
    height: 8rem;
    background: white;
    overflow: hidden;
    position: relative;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;
    
}

.product>img{
    top: 5rem;
    height: 11rem;
    width: 6rem;
    transform: rotate(-20deg);

}



@media screen and  (max-width:640px)
{
     .menu{
        margin-left: -2rem;
        gap:0.5rem;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.2rem;


     }  
     
     .container h1{
        text-align: center;

     }

     .product{
        width: 12rem;
        height: 8rem;
        background: white;
        overflow: hidden;
        position: relative;
        padding: 1rem;
        display: flex;
        border-radius: 1rem;
        margin-left: 2.2rem;
        
    }

    .menu>:nth-child(3){
        margin-top: -1.8rem;
    }
      
}
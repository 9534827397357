.container{
    padding: 0rem 2rem ;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
}

.wrapper{
    display: flex;
    align-items: baseline;
    justify-content: center;
    position: relative;
    margin-top: 50%;
}

.wrapper>img{
    width: 30rem;
    position: absolute;
    bottom:0;
}


.blueCircle{
    width: 30rem;
    height: 30rem;
    border-radius: 50%;
    z-index: -99;
    position: absolute;
    bottom: 0;
    background: linear-gradient(135.74deg, #0bb5ff 14.09% , #6dd3fe 83.81%);
}

.cart2{
    position: absolute;
    bottom: 25%;
    right : 5%;
    display:flex;
    gap: 1rem;
    align-items: center;
}

.cart2>svg{
    width: 30px;
    height: 30px;
    background: white;
    padding : 10px;
    border-radius: 50%;
    border : 6px solid var(--black);
    margin-bottom: 50%;
}

.signup{
    display: flex;
    gap: 1rem;
    align-items: center;
    background: white;
    padding : 10px;
    font-size:  0.8rem;
    border-radius: 15px;
    box-shadow: var(--shadow);
    margin-bottom: 50%;
    

}

.signup>:first-child{
    display: block;
    width: 6rem;
}

.signup>:nth-child(2){
    border-radius: 50%;
    border: 1px solid skyblue;
    display: flex;
    height: 20px;
    width: 20px;
    align-items: center;
    justify-content: center;
}


.h_sides{
    display: grid;
    grid-template-rows: 1fr 1fr;

}

.text1{
    text-transform: uppercase;
    font-size: 1.5rem;
    width: min-content;
    display: block ;
    font-weight: 600;
}
.text2{
    display: flex;
    flex-direction: column;
    width: min-content;
    color: black;

}
 
.text2>:first-child{
    font-weight: 800;
    font-size: 2.5rem;


}

.text2>:nth-child(2){
    display: block;
}

.traffic{
    display: flex;
    flex-direction: column;
    text-align: right;

}
 .traffic>:first-child{
    font-weight: 800;
    font-size: 2.5rem;

 }

 

 .customers{
    display: flex;
    flex-direction: column;
    text-align: right;
 }

 .customers>:first-child{
    font-weight: 800;
    font-size: 2.5rem;
 }

@media screen and (max-width: 640px) {
    .text1, .text2>span:nth-of-type(2){
        font-size: .9rem;
        text-align: auto;
    }
    .text1{
        align-self: center;
    }
   .text2{
    width: 70%;
    align-self: center; 
   }

   .traffic{
    font-size: .9rem;
    align-self: center;
    margin-top: 1rem;

   }
    
   

    .text2>span:nth-of-type(1){
        font-size: 1.3rem;
        text-align: left;
    }
    .wrapper>img{
        width: 100%;
        height: auto;

    }
    .blueCircle{
    width: 12rem;
    height: 12rem;
}

    .traffic>:first-child{
        font-size: 1.3rem;
    }

    .customers>:first-child{
        font-size: 1.3rem;
    }
    .customers>:last-child{
        text-align: auto;
    }
    
    .wrapper{
        flex-direction: column;
        display: grid;
    }
    
    .cart2{
        display: none;
    }
    
    

    .container{
        grid-template-columns: 20% auto 20%;
        display: grid;
        grid-gap: 10px;
 }
    
}